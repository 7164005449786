





















































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";

import {
  SysSettingOrderCreateOrUpdateDto,
  InventoryDeductionMethod,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "SysSettingOrder",
  components: {},
})
export default class SysSettingOrder extends Vue {
  @Ref() dataForm!: ElForm;

  form: SysSettingOrderCreateOrUpdateDto = {};
  editId: undefined | number;
  inventoryDeductionMethodArray: any = [
    { name: "拍下减库存", value: InventoryDeductionMethod.CreateOrder },
    { name: "付款减库存", value: InventoryDeductionMethod.Pay },
  ];

  created() {
    this.fetchData();
  }

  // 获取表数据
  fetchData() {
    api.sysSettingOrder.getCurrent().then((res) => {
      this.form = res!;
      this.editId = res!.id;
    });
  }

  save() {
    if (this.editId) {
      api.sysSettingOrder
        .update({
          body: this.form,
        })
        .then(() => {
          this.$message.success("更新成功");
        });
    } else {
      api.sysSettingOrder
        .create({
          body: this.form,
        })
        .then(() => {
          this.$message.success("创建成功");
        });
    }
  }
}
